(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .directive('riskRuleBuilder', ['DynamicForm', riskRuleBuilder]);

    function riskRuleBuilder (DynamicForm) {
        var directive = {
            restrict: 'E',
            link:  postLink,
            transclude: true,
            templateUrl: "app/entities/risk-rule/risk-rule-builder.template.html",
            scope: {
                riskRuleStr: "=riskRuleEntity"
            }
        };

        return directive;

        // EXAMPLE RULES
        // Single Condition Example: { "==" : [1, 1] }
        // AND Condition Example:
        // {"AND" : [
        //   { ">" : [3,1] },
        //   { "<" : [1,3] }
        // ] }
        // GREATER_THAN_CONVERTED/LESS_THAN_CONVERTED Condition Example: {"GREATER_THAN_CONVERTED":["standardResidential.site.propertyArea",5,"standardResidential.site.areaUnit","ACRES"]}
        // AFTER_DATE_SIGNED Condition Example: {"AFTER_DATE_SIGNED":["standardResidential.certification.appraiserInspectedDate"]}

        // VARS
        // scope.riskRuleStr - double binded value stored in db
        // scope.riskRule - rule represented as obj
        // scope.riskRuleCondition - rule condition - "AND", "OR", ""
        // scope.expressionList - list of expressions joined by condition [{operator: "==", selectedField0: "standardResidential", selectedField1: "assignment", selectedField2: "appraisalPurpose", value: "test"}, ...]

        function postLink(scope, elm, attrs) {
            init();

            function init() {
                initStaticArrays();
                initFetchFormDefinitions();
            }

            function initStaticArrays() {
                // Setup available operations list
                scope.availableOperations = {
                    "string": ["==", "!=", "IN", "NOT_IN"],
                    "integer": ["==", "!=", ">=", "<=", ">", "<"],
                    "decimal": ["==", "!=", ">=", "<=", ">", "<", "GREATER_THAN_CONVERTED", "LESS_THAN_CONVERTED", "GREATER_THAN_PERCENTAGE"],
                    "boolean": ["==", "!="],
                    "multiselect": ["IN", "NOT_IN", "COUNT_GREATER_THAN"],
                    "date": ["OLDER_THAN_DAYS", "AFTER_DATE_SIGNED"],
                    "enum": ["==", "!="]
                };
                scope.specialOperations = ["ANY_COMP_SALE_DATE_TOO_OLD", "FEWER_THAN_BEDROOM_VARIANCE", "ANY_EXCEEDING_NET_GROSS_PERCENTAGE", "VALUE_GREATER_THAN_MAX_SALE_PRICE",
                    "MONTHS_ON_MARKET", "FEWER_THAN_SALES_DATE_WITHIN", "AT_LEAST_COMPS_DISTANCE_FROM", "FEWER_THAN_DESIGN_SIMILAR"];

                // Setup available fields list
                scope.availableForms = ["standardResidentialCuspap2024", "costApproachCuspap2024", "incomeApproachCuspap2024"];
                scope.availableFieldPaths = [];
                scope.expressionList = [];

                scope.numFormsToInitialize = scope.availableForms.length;
                scope.numFormsInitialized = 0;
            }

            function isSpecialOperation(operator) {
                return operator && scope.specialOperations.indexOf(operator) !== -1;
            }

            function isNewRule(operator) {
                return operator === undefined && scope.expressionList.length === 0;
            }

            function initFetchFormDefinitions() {

                // We need to wait until array scope.availableFieldPaths has been fully populated for all forms.

                angular.forEach(scope.availableForms, function (formName) {
                    // Start an asynchronous request for every form.
                    DynamicForm.getTypeCategorizedFormDefinition({formName: formName}, function (res) {
                        var formNamePrefix = formName + ".";
                        buildFieldPathList(res, formNamePrefix);

                        angular.forEach(res.sections, function (section) {
                            var sectionNamePrefix = formNamePrefix + section.name + ".";
                            buildFieldPathList(section, sectionNamePrefix);
                        });

                        // Only proceed to the next step if we have finished processing all the forms.
                        scope.numFormsInitialized += 1;
                        if (scope.numFormsInitialized >= scope.numFormsToInitialize) {
                            initDefineWatcher();
                            initCheckRule();
                        }
                    });
                });
            }

            function initDefineWatcher() {
                scope.$watch('expressionList', function (newValue, oldValue) {
                    angular.forEach(scope.expressionList, function (expression) {
                        var tempFieldPath = scope.buildFieldPathFromExpression(expression);
                        if (!scope.availableFieldPaths.some(function (fieldPath) {
                            return fieldPath.name === tempFieldPath;
                        })) {
                            expression.selectedField2 = "";
                        }
                        if (scope.specialOperations.indexOf(expression.operator) !== -1) {
                            expression.selectedField0 = "";
                            expression.selectedField1 = "";
                            expression.selectedField2 = "";
                        }
                    });
                    scope.fixValueFormats();
                    scope.ruleUpdated();
                }, true);
            }

            function initCheckRule() {
                // Setup risk rule from stored string
                scope.riskRule = {};
                if (scope.riskRuleStr) {
                    scope.riskRule = angular.fromJson(scope.riskRuleStr);
                }

                // Setup risk condition from risk rule
                if (scope.riskRule["AND"]) {
                    scope.riskRuleCondition = "AND";
                } else if (scope.riskRule["OR"]) {
                    scope.riskRuleCondition = "OR";
                } else {
                    scope.riskRuleCondition = "";
                }

                var ruleExpressionArr = scope.riskRuleCondition ? scope.riskRule[scope.riskRuleCondition] : [scope.riskRule];

                // Setup expression list from risk rule
                angular.forEach(ruleExpressionArr, function (ruleExpression) {
                    var operator = Object.keys(ruleExpression)[0];

                    if (isSpecialOperation(operator)) {
                        scope.expressionList.push({
                            operator: operator,
                            specialValue1: ruleExpression[operator][0],
                            specialValue2: ruleExpression[operator][1]
                        })
                    } else if (isNewRule(operator)) {
                        scope.expressionList.push({
                            operator: "",
                            selectedField0: "",
                            selectedField1: "",
                            selectedField2: "",
                            value: ""
                        });
                    } else {
                        var parsedRuleExpression = ruleExpression[operator][0].split('.');

                        var formattedRuleExpressionObj = {
                            operator: operator,
                            selectedField0: parsedRuleExpression[0],
                            selectedField1: parsedRuleExpression[1],
                            value: ruleExpression[operator][1]
                        };

                        if (parsedRuleExpression.length === 3) {
                            formattedRuleExpressionObj.selectedField2 = parsedRuleExpression[2];
                        }

                        scope.expressionList.push(formattedRuleExpressionObj);
                    }
                });
            }

            // Functions to build field path list from form definitions
            function buildFieldPathList(formDefinition, prefix) {
                pushFieldPaths(formDefinition.textFields, prefix, 'string');
                pushFieldPaths(formDefinition.dateFields, prefix, 'date');
                pushFieldPaths(formDefinition.decimalFields, prefix, 'decimal');
                pushFieldPaths(formDefinition.integerFields, prefix, 'integer');
                pushFieldPaths(formDefinition.booleanFields, prefix, 'boolean');
                pushFieldPaths(formDefinition.multiselectFields, prefix, 'multiselect');
                pushFieldPaths(formDefinition.enumFields, prefix, 'enum');
            }

            function pushFieldPaths(fields, prefix, type) {
                angular.forEach(fields, function(field) {
                    scope.availableFieldPaths.push({ name: prefix + field, type: type });
                });
            }

            // Function to filter all available fields by value of path at index in split array (ie: ["standardResidential","assignment","appraisalPurpose"])
            scope.filterFieldPaths = function(pathIndex0, pathIndex1) {
                if (!scope.availableFieldPaths || !scope.availableFieldPaths.length)
                    return [];
                else
                    return scope.availableFieldPaths.filter(function(path) {
                        return (pathIndex1)
                            ? path.name.split('.')[0] === pathIndex0 && path.name.split('.')[1] === pathIndex1
                            : path.name.split('.')[0] === pathIndex0;
                    }).map(function(path) {
                        return (pathIndex1)
                            ? path.name.split('.')[2]
                            : path.name.split('.')[1];
                    }).filter(function (value, index, self) {
                        return value && self.indexOf(value) === index;
                    });
            };

            // Update rule condition (AND, OR, single rule)
            scope.setCondition = function(condition) {
                if (scope.riskRuleCondition === condition) {
                    scope.expressionList = [ scope.expressionList[0] ];
                } else if (!scope.riskRuleCondition) {
                    scope.expressionList.push({});
                }

                scope.riskRuleCondition = (scope.riskRuleCondition === condition) ? '' : condition;

                scope.ruleUpdated();
            };

            // Build risk rule from rule list and condition (AND, OR, single rule)
            scope.buildRiskRule = function(operator) {
                scope.riskRule = {};
                if (scope.riskRuleCondition) {
                    scope.riskRule[scope.riskRuleCondition] = [];
                    angular.forEach(scope.expressionList, function(expression) {
                        scope.riskRule[scope.riskRuleCondition].push(buildExpression(expression));
                    });
                } else {
                    scope.riskRule = buildExpression(scope.expressionList[0]);
                }
            };

            function buildExpression(expression) {
                var tempFieldPath = scope.buildFieldPathFromExpression(expression);
                var tempExpression = {};

                if (scope.specialOperations.indexOf(expression.operator) !== -1) {
                    if (expression.operator === 'VALUE_GREATER_THAN_MAX_SALE_PRICE') {
                        tempExpression[expression.operator] = [];
                    } else if (['ANY_COMP_SALE_DATE_TOO_OLD','MONTHS_ON_MARKET', 'FEWER_THAN_DESIGN_SIMILAR'].indexOf(expression.operator) !== -1) {
                        tempExpression[expression.operator] = [expression.specialValue1];
                    } else {
                        tempExpression[expression.operator] = [expression.specialValue1, expression.specialValue2];
                    }
                } else if (expression.operator && scope.availableFieldPaths.some(function(fieldPath) { return fieldPath.name === tempFieldPath; }) && (expression.value || scope.getValueType(expression) === 'none')) {
                    if (scope.needsConversion(expression.operator)) {
                        tempExpression[expression.operator] = [tempFieldPath, expression.value, expression.currentUnitPath, expression.conversionUnit];
                    } else if (scope.isPercentage(expression.operator)) {
                        tempExpression[expression.operator] = [tempFieldPath, expression.value, expression.testFieldPath];
                    } else if (scope.getValueType(expression) === 'none') {
                        tempExpression[expression.operator] = [tempFieldPath];
                    } else {
                        tempExpression[expression.operator] = [tempFieldPath, expression.value];
                    }
                }
                return tempExpression;
            }

            // Parse int or float value types for each rule
            scope.fixValueFormats = function() {
                angular.forEach(scope.expressionList, function(rule) {
                    var valueType = scope.getValueType(rule);
                    if (valueType === 'integer') {
                        rule.value = parseInt(rule.value);
                    } else if (valueType === 'decimal') {
                        rule.value = parseFloat(rule.value);
                    }
                });
            };

            // Update risk rule string from risk rule
            scope.ruleUpdated = function() {
                scope.buildRiskRule();
                scope.riskRuleStr = angular.toJson(scope.riskRule);
            };

            // Builds the field path for the expression
            scope.buildFieldPathFromExpression = function(expression) {
                if (!expression) return "";

                return (!expression.selectedField2)
                            ? expression.selectedField0 + "." + expression.selectedField1
                            : expression.selectedField0 + "." + expression.selectedField1 + "." + expression.selectedField2;
            };

            // Determines type of field for expression
            scope.getFieldType = function(expression) {
                var pathObj = scope.availableFieldPaths.find(function(tmpPath) { return tmpPath.name === scope.buildFieldPathFromExpression(expression); });
                return (pathObj && pathObj.type) ? pathObj.type : "string";
            };

            // Determines if a certain operation can be performed on field with path
            scope.canPerformOperation = function(op, expression) {
                var fieldType = scope.getFieldType(expression);
                return scope.specialOperations.indexOf(op) !== -1 || scope.availableOperations[fieldType].indexOf(op) !== -1;
            };

            // Determines type of value for field with path
            scope.getValueType = function(expression) {
                var fieldType = scope.getFieldType(expression);
                if (['decimal'].indexOf(fieldType) !== -1) {
                    return 'decimal';
                } else if (['boolean'].indexOf(fieldType) !== -1) {
                    return 'boolean';
                } else if (['date'].indexOf(fieldType) !== -1 && expression.operator === "AFTER_DATE_SIGNED") {
                    return 'none';
                } else if (['integer', 'date'].indexOf(fieldType) !== -1) {
                    return 'integer';
                } else if (['multiselect'].indexOf(fieldType) !== -1 && expression.operator === "COUNT_GREATER_THAN") {
                    return 'integer';
                } else {
                    return 'text';
                }
            };

            scope.needsConversion = function(operator) {
                return operator.indexOf('_CONVERTED') !== -1;
            };

            scope.isPercentage = function(operator) {
                return scope.specialOperations.indexOf(operator) === -1 && operator.indexOf('_PERCENTAGE') !== -1;
            };


        }
    }
})();
